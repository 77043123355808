import Loading from 'vue-loading-overlay';
import { addressFormatter } from '@/shared/fomaters';
import { makeDirLink } from '@/shared/google/helpers';
import { mapFilters } from '~filters';

import { getPassengers } from '@/cruds/transports.crud';
import { getAddress } from '@/cruds/address.crud';

import { isStarted, getTravelModeFromTransportMean } from '@/modules/transports/utils';

import PassengersListModal from '@/modules/transports/Modals/PassengersListModal';

const defTime = '0001-01-01T00:00:00Z';

export default {
	name: 'MixinTransportsModule',
	components: { Loading, PassengersListModal },
	data() {
		return {
			isPassengersModalOpen: false,
			passengersList: []
		};
	},
	watch: {
		/**
		 * @param {Boolean} status
		 */
		isPassengersModalOpen(status) {
			if (status) return;
			this.passengersList = [];
		}
	},
	methods: {
		...mapFilters(['formatTime', 'datesDiff', 'capitalize']),

		/**
		 * @param {String} transportId
		 * @return {String}
		 */
		async handleShowPassengers(transportId) {
			this.passengersList = await getPassengers(transportId);
			this.isPassengersModalOpen = true;
		},

		/**
		 * @param {Object} address
		 * @return {String}
		 */
		rendFormattedAddress(address) {
			return addressFormatter(address);
		},

		/**
		 * @param {Object} item
		 */
		async handleOpenNewWindow(item) {
			const moved = item.curLat !== 0 && item.curLng !== 0;

			const origin = isStarted(item) && moved ? { lat: item.curLat, lng: item.curLng } : await getAddress(item.fromAddressId);
			const destination = await getAddress(item.toAddressId);
			const { travelMode } = getTravelModeFromTransportMean(item.type);

			return makeDirLink(origin, destination, {
				windowTitle: this.FormMSG(213421, 'Google map link'),
				travelMode
			});
		},

		/**
		 * @param {Object} transport
		 * @return {String}
		 */
		checkTransportStatus(transport) {
			let status = 'home';
			if (defTime !== transport.effDepartureTime && transport.effArrivalTime === defTime) {
				status = 'started';
			}
			if (defTime !== transport.effDepartureTime && transport.effArrivalTime !== defTime) {
				status = 'arrived';
			}
			return status;
		}
	}
};
