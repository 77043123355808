<template>
	<div>
		<b-row class="header card-header pb-0 mr-0 ml-0">
			<b-col cols="10">
				<h5 class="text-capitalize">
					<b>{{ rendTime(cardData.departureDate) }}</b>
				</h5>
			</b-col>
			<b-col v-if="!isProduction" cols="2" class="text-right" style="padding-right: 0px !important">
				<b-dropdown
					no-caret
					dropleft
					class="custom-dropdown"
					no-flip
					offset="25"
					variant="none"
					style="border-right: 0px !important; padding-top: 0px !important; position: relative"
				>
					<template #button-content>
						<more-vertical />
					</template>
					<b-dropdown-item-button v-if="isArrived(cardData) === false" @click="handleStartStop(cardData)"
						><Car /> &nbsp;
						<b v-if="isStarted(cardData) === false">{{ FormMSG(60, 'Start transport') }}</b>
						<b v-else> {{ FormMSG(70, 'Stop transport') }}</b>
					</b-dropdown-item-button>
					<b-dropdown-item-button :disabled="cardData.fromAddressId === 0 || cardData.toAddressId === 0" @click="viewInMap(cardData)"
						><MapPin /> &nbsp; <b>{{ FormMSG(61, 'View in map') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button :disabled="cardData.passenger.length === 0" @click="handleClickViewPassengers(cardData)"
						><Users /> &nbsp; <b>{{ FormMSG(62, 'View passengers') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button @click="handleEditTransport(cardData)">
						<Edit /> &nbsp; <b>{{ FormMSG(65, 'Edit') }}</b>
					</b-dropdown-item-button>
					<b-dropdown-item-button v-if="canRemoveTransport" @click="handleDelete(cardData.id)"
						><Trash2 color="tomato" /> &nbsp; <b style="color: tomato">{{ FormMSG(63, 'Remove') }}</b></b-dropdown-item-button
					>
				</b-dropdown>
			</b-col>
			<b-col v-if="isProduction" cols="3" class="text-right" style="padding-right: 0px !important">
				<b-dropdown
					no-caret
					dropleft
					class="custom-dropdown"
					no-flip
					offset="25"
					variant="none"
					style="border-right: 0px !important; padding-top: 0px !important; position: relative"
				>
					<template #button-content>
						<more-vertical />
					</template>
					<b-dropdown-item-button @click="handleCopyTransport(cardData)">
						<component :is="getLucideIcon('Copy')" :size="16" /> &nbsp; <b>{{ FormMSG(867786, 'Copy') }}</b>
					</b-dropdown-item-button>
					<b-dropdown-item-button :disabled="cardData.fromAddressId === 0 || cardData.toAddressId === 0" @click="handleOpenNewWindow(cardData)"
						><component :is="getLucideIcon('MapPin')" :size="16" /> &nbsp; <b>{{ FormMSG(61, 'View in map') }}</b></b-dropdown-item-button
					>
					<b-dropdown-item-button v-if="cardData.arrivalTime !== '0001-01-01T00:00:00Z'" @click="handleFromThereToTransport(cardData.id)">
						<component :is="getLucideIcon('Car')" :size="16" /> &nbsp; <b>{{ FormMSG(689689, 'From here go to') }}</b>
					</b-dropdown-item-button>
					<b-dropdown-item-button @click="handleEditTransport(cardData)">
						<Edit :size="16" /> &nbsp; <b>{{ FormMSG(65, 'Edit') }}</b>
					</b-dropdown-item-button>
					<b-dropdown-item-button @click="handleDeleteTransport(cardData)"
						><component :is="getLucideIcon('Trash')" :size="16" color="tomato" /> &nbsp;
						<b style="color: tomato">{{ FormMSG(63, 'Remove') }}</b></b-dropdown-item-button
					>
				</b-dropdown>
			</b-col>
		</b-row>
		<div class="card card-collapse" :class="{ 'is-openned': isOpen }">
			<div class="card-header pt-0" @click="handleOpenCollapse()">
				<div>
					<h5 v-if="cardData.extraInfo != ''">{{ cardData.extraInfo }}</h5>
					<div v-if="rendHours(cardData.departureTime)" class="row pl-3">
						<Clock4 :size="15" color="#47C7BF" class="mr-2" /> &nbsp;
						<h6>
							{{ FormMSG(2, 'Departure') + ': ' + rendHours(cardData.departureTime) }}
							{{ rendHours(cardData.arrivalTime) ? ' - ' + FormMSG(3, 'Arrival') + ': ' + rendHours(cardData.arrivalTime) : null }}
						</h6>
					</div>
				</div>
				<i class="icon-arrow-down" />
			</div>
			<b-card-body v-show="isOpen" :class="{ 'is-openned': isOpen }" class="collapse-card-body bg-color-chefs-hat" style="margin-top: -12px">
				<div class="row pl-3 mb-2" style="flex-direction: row; align-items: center">
					<ArrowLeftRight color="#47C7BF" :size="15" style="transform: rotate(90deg)" class="mr-2" />
					<div class="fs-13">
						{{ FormMSG(10, 'From') + ': ' }}<span>{{ rendFormattedAddress(cardData.fromAddress) }}</span>
						<br />
						{{ FormMSG(11, 'To') + ': ' }}<span>{{ rendFormattedAddress(cardData.toAddress) }}</span>
					</div>
				</div>
				<div class="row pl-3 mb-2">
					<Sprout color="#47C7BF" :size="15" class="mr-2" />
					<div class="fs-13">{{ rendCo2(cardData.kgCoTwo) + ' - ' + cardData.km + distanceUnit }}</div>
				</div>
				<div class="row pl-3 mb-2" style="flex-direction: row; align-items: center">
					<Car color="#47C7BF" :size="15" class="mr-2" />
					<div class="fs-13">
						{{ cardData.vehicule.name ? '' : rendTransportationMean(cardData.type) }}
						{{ cardData.vehicule.name && cardData.vehicule.name + ' (' + cardData.vehicule.numberPlate + ')' }}
					</div>
				</div>
				<div class="row pl-3" v-if="rendDifference(cardData)">
					<Clock4 color="#47C7BF" :size="15" class="mr-2" />
					<div class="fs-13">{{ FormMSG(8, 'Duration') }}: {{ rendDifference(cardData) }}</div>
				</div>
			</b-card-body>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { ArrowLeftRight, Car, Clock4, Edit, MapPin, MoreVertical, Sprout, Trash2, Users } from 'lucide-vue';
import transportMixin from '@/modules/transports/transport.mixin';
import moment from 'moment';
import { rendKgCo2 } from '../../../../shared/helpers';
import { store } from '@/store/index';
import _ from 'lodash';
import globalMixin from '@/mixins/global.mixin';
import { TRANSPORTATION_MEAN_MENU } from '@/shared/constants';

export default {
	mixins: [languageMessages, transportMixin, globalMixin],
	props: {
		cardData: {
			type: Object,
			required: false
		},
		isProduction: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	name: 'CollapsibleCard',
	components: { MoreVertical, Car, MapPin, Users, Trash2, Clock4, Sprout, ArrowLeftRight, Edit },
	computed: {
		canRemoveTransport() {
			if (parseInt(this.cardData.userId) === parseInt(this.cardData.createdBy)) {
				return true;
			}

			return false;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	created() {
		moment.locale(this.lang);
	},
	methods: {
		handleCopyTransport(item) {
			this.$emit('collapsible-card:copy-transport', item);
		},
		handleOpenNewWindow(item) {
			this.$emit('collapsible-card:view-map', item);
		},
		handleFromThereToTransport(id) {
			this.$emit('collapsible-card:from-there-to-transport', id);
		},
		handleDeleteTransport(item) {
			this.$emit('collapsible-card:delete-transport', item);
		},
		isArrived(transport) {
			if (transport.effArrivalTime === '0001-01-01T00:00:00Z') {
				return false;
			}

			return true;
		},

		/**
		 * @param {Object} transport
		 * @return {Boolean}
		 */
		isStarted(transport) {
			if (transport.effDepartureTime === '0001-01-01T00:00:00Z') {
				return false;
			}

			return true;
		},
		rendDifference(data) {
			const start = moment(data.departureTime);
			const end = moment(data.arrivalTime);
			const duration = moment.duration(end.diff(start));
			if (duration.hours() < 0 || duration.minutes < 0) {
				return;
			} else {
				const hours = duration.hours();
				const minutes = duration.minutes();

				const diff = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
				return diff;
			}
		},
		rendTransportationMean(transportType) {
			if ([1, 2, 3].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BOAT, transportType);
			}
			if ([4, 5].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BUS, transportType);
			}
			if ([6, 7, 8].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.CAR, transportType);
			}
			if ([17, 18, 19, 20, 21].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.FREIGHT, transportType);
			}
			if ([9, 10, 11, 12, 13, 14, 15, 16].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.OTHER, transportType);
			}
		},
		handleClickViewPassengers(data) {
			this.$emit('transport:view-passenger', data);
		},
		handleEditTransport(data) {
			this.$emit('transport:edit', data);
		},
		handleDelete(id) {
			this.$emit('transport:delete', id);
		},
		viewInMap(data) {
			this.$emit('transport:openMap', data);
		},
		handleStartStop(data) {
			this.$emit('transport:startOrStop', data);
		},
		rendCo2(co) {
			return rendKgCo2(co);
		},
		handleOpenCollapse() {
			this.isOpen = !this.isOpen;
		},
		rendTime(time) {
			if (!_.isNil(time)) {
				return moment(time).format('dddd, DD MMMM YYYY');
			}
			return;
		},
		rendHours(hours) {
			if (!_.isNil(hours)) {
				if (hours === '0001-01-01T00:00:00Z') return;
				return moment(hours).format('HH:mm');
			}
			return;
		}
	}
};
</script>
<style lang="scss" scoped>
.fs-13 {
	font-size: 13px;
}
.card.card-collapse {
	& > .card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > i {
			cursor: pointer;
			transition: transform ease-in-out 200ms;
		}
	}
	&.is-openned > .card-header > i {
		transform: rotate(180deg);
	}
}
</style>
