var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-text",
                              {
                                class: {
                                  "bg-color-white": _vm.$screen.width < 992,
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              id: "checkbox-today-only",
                                              name: "checkbox-today-only",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            on: {
                                              change: _vm.handleChangeTodayOnly,
                                            },
                                            model: {
                                              value: _vm.todayOnly,
                                              callback: function ($$v) {
                                                _vm.todayOnly = $$v
                                              },
                                              expression: "todayOnly",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    9689689,
                                                    "Today only"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "5" } },
                                      [
                                        _vm.transportsList.length > 0
                                          ? _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      37,
                                                      "Type to Search"
                                                    ),
                                                  },
                                                  model: {
                                                    value: _vm.filter,
                                                    callback: function ($$v) {
                                                      _vm.filter = $$v
                                                    },
                                                    expression: "filter",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "btn-search",
                                                      },
                                                      [
                                                        _vm.filter.length === 0
                                                          ? _c(
                                                              _vm.getLucideIcon(
                                                                "Search"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#FFFFFF",
                                                                  size: 16,
                                                                  "stroke-width": 2.5,
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              _vm.getLucideIcon(
                                                                "X"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#FFFFFF",
                                                                  size: 16,
                                                                  "stroke-width": 2.5,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.filter =
                                                                        ""
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "text-right hide-on-tablet",
                                        attrs: { md: "7" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "xl",
                                            },
                                            on: {
                                              click:
                                                _vm.handleClickAddTransport,
                                            },
                                          },
                                          [
                                            _c(
                                              _vm.getLucideIcon("PlusCircle"),
                                              {
                                                tag: "component",
                                                attrs: { size: 16 },
                                              }
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    65,
                                                    "Add transport"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", xl: "12" } },
                                      [
                                        _vm.transportsList
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.transportsList,
                                                  function (transport, i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "hide-on-desktop",
                                                      },
                                                      [
                                                        _c("CollapsibleCard", {
                                                          attrs: {
                                                            cardData: transport,
                                                          },
                                                          on: {
                                                            "transport:startOrStop":
                                                              _vm.handleUpdateTransport,
                                                            "transport:openMap":
                                                              _vm.handleOpenNewWindow,
                                                            "transport:delete":
                                                              _vm.handleDelete,
                                                            "transport:edit":
                                                              _vm.rowClicked,
                                                            "transport:view-passenger":
                                                              _vm.handleClickViewPassenger,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm.transportsList.length > 0
                                                  ? _c("b-table", {
                                                      ref: "myTable",
                                                      staticClass:
                                                        "hide-on-tablet",
                                                      staticStyle: {
                                                        "text-align": "left",
                                                      },
                                                      attrs: {
                                                        "selected-variant":
                                                          "primary",
                                                        hover: "",
                                                        responsive: "sm",
                                                        items:
                                                          _vm.transportsList,
                                                        fields:
                                                          _vm.transportsFields,
                                                        filter: _vm.filter,
                                                        bordered: "",
                                                        small: "",
                                                        "head-variant": "dark",
                                                        "empty-text":
                                                          _vm.FormMSG(
                                                            158,
                                                            "No document package found"
                                                          ),
                                                        "show-empty": "",
                                                        "tbody-tr-class": "p-2",
                                                      },
                                                      on: {
                                                        "row-clicked":
                                                          _vm.rowClicked,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "cell(departureArrivalTime)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.rendTime(
                                                                        item.departureTime
                                                                      )
                                                                        ? _vm.rendTime(
                                                                            item.departureTime
                                                                          )
                                                                        : ""
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.rendTime(
                                                                        item.arrivalTime
                                                                      )
                                                                        ? " - " +
                                                                            _vm.rendTime(
                                                                              item.arrivalTime
                                                                            )
                                                                        : ""
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(km)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      item.km +
                                                                        " " +
                                                                        _vm.distanceUnit
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(kgCoTwo)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.rendCo2(
                                                                        item.kgCoTwo
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(type)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.rendTransportationMean(
                                                                        item.type
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(routeAddress)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                item.fromAddress
                                                                  .street ===
                                                                  "" &&
                                                                item.toAddress
                                                                  .street === ""
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " ... "
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.rendFormattedAddress(
                                                                                    item.fromAddress
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-arrow-right",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#00a09c",
                                                                          },
                                                                      }),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.rendFormattedAddress(
                                                                                    item.toAddress
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "cell(action)",
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "b-dropdown",
                                                                  {
                                                                    staticClass:
                                                                      "custom-dropdown",
                                                                    staticStyle:
                                                                      {
                                                                        "border-right":
                                                                          "0px !important",
                                                                        "padding-top":
                                                                          "0px !important",
                                                                        position:
                                                                          "relative",
                                                                      },
                                                                    attrs: {
                                                                      "no-caret":
                                                                        "",
                                                                      dropleft:
                                                                        "",
                                                                      "no-flip":
                                                                        "",
                                                                      block: "",
                                                                      size: "sm",
                                                                      offset:
                                                                        "25",
                                                                      variant:
                                                                        "none",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "button-content",
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "more-vertical"
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm.isArrived(
                                                                      item
                                                                    ) === false
                                                                      ? _c(
                                                                          "b-dropdown-item-button",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleUpdateTransport(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "car"
                                                                            ),
                                                                            _vm._v(
                                                                              "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                            _vm.isStarted(
                                                                              item
                                                                            ) ===
                                                                            false
                                                                              ? _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          60,
                                                                                          "Start transport"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            70,
                                                                                            "Stop transport"
                                                                                          )
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "b-dropdown-item-button",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            item.fromAddressId ===
                                                                              0 ||
                                                                            item.toAddressId ===
                                                                              0,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleOpenNewWindow(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "map-pin"
                                                                        ),
                                                                        _c(
                                                                          "b",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  61,
                                                                                  "View in map"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-dropdown-item-button",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            item
                                                                              .passenger
                                                                              .length ===
                                                                            0,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleClickViewPassenger(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "users"
                                                                        ),
                                                                        _vm._v(
                                                                          "   "
                                                                        ),
                                                                        _c(
                                                                          "b",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  62,
                                                                                  "View passengers"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm.canRemoveTransport(
                                                                      item
                                                                    )
                                                                      ? _c(
                                                                          "b-dropdown-item-button",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleDelete(
                                                                                    item.id
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "trash",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "tomato",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "tomato",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      63,
                                                                                      "Remove"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        823714922
                                                      ),
                                                    })
                                                  : _c(
                                                      "b-alert",
                                                      {
                                                        staticClass: "full",
                                                        attrs: {
                                                          show: "",
                                                          variant: "info",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                786,
                                                                "No Item to show"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "b-alert",
                                              {
                                                staticClass: "full",
                                                attrs: {
                                                  show: "",
                                                  variant: "error",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        798766,
                                                        "Something wrong happened during the loading"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "footer-fixed hide-on-desktop" },
              [
                _c(
                  "b-col",
                  { attrs: { md: "12" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "w-100 mb-2",
                        attrs: { size: "lg" },
                        on: { click: _vm.handleClickAddTransport },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(700, "Add new transport")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("transport-modal", {
              attrs: {
                open: _vm.showModalTransport,
                "edit-data": _vm.currTransportToEdit,
                isMyTransport: _vm.isMytransport,
                isCreated: _vm.isCreated,
              },
              on: {
                "transport-modal:close": _vm.handleTransportModalClose,
                "transport-modal:submit-success":
                  _vm.handleTransportModalSubmitSuccess,
              },
            }),
            _c("PassengersListModal", {
              attrs: { passengers: _vm.passengerList },
              on: { input: _vm.handleInputPassengersListModal },
              model: {
                value: _vm.showPassengerListModal,
                callback: function ($$v) {
                  _vm.showPassengerListModal = $$v
                },
                expression: "showPassengerListModal",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }