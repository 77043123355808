<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		:title="FormMSG(111, 'Passengers')"
		:ok-title="FormMSG(113, 'Cancel')"
		ok-only
		ok-variant="outline-primary"
		@ok="handleCloseModal"
	>
		<ul v-if="$has(passengers) && passengers.length > 0" class="list-container">
			<li v-for="(passenger, i) in passengers" :key="i" class="content_sb_middle full">
				<span>
					{{ passenger.name | capitalize }}&ensp;
					{{ passenger.firstName | capitalize }}
				</span>
				<a :href="`tel://${passenger.phone}`">
					<component :is="getLucideIcon('Phone')" :size="16" />
				</a>
			</li>
		</ul>

		<b-alert v-else show variant="default">
			{{ FormMSG(789789, 'No passengers to show') }}
		</b-alert>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import modalMixin from '@/mixins/modal.mixin';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'PassengersListModalTransportModuleComponent',
	mixins: [languageMessages, modalMixin, globalMixin],
	props: {
		passengers: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	methods: {
		handleCloseModal() {
			this.isModalOpen = false;
		}
	}
};
</script>
