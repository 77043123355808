var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(111, "Passengers"),
        "ok-title": _vm.FormMSG(113, "Cancel"),
        "ok-only": "",
        "ok-variant": "outline-primary",
      },
      on: { ok: _vm.handleCloseModal },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _vm.$has(_vm.passengers) && _vm.passengers.length > 0
        ? _c(
            "ul",
            { staticClass: "list-container" },
            _vm._l(_vm.passengers, function (passenger, i) {
              return _c(
                "li",
                { key: i, staticClass: "content_sb_middle full" },
                [
                  _c("span", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm._f("capitalize")(passenger.name)) +
                        " \n\t\t\t\t" +
                        _vm._s(_vm._f("capitalize")(passenger.firstName)) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _c(
                    "a",
                    { attrs: { href: `tel://${passenger.phone}` } },
                    [
                      _c(_vm.getLucideIcon("Phone"), {
                        tag: "component",
                        attrs: { size: 16 },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _c("b-alert", { attrs: { show: "", variant: "default" } }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.FormMSG(789789, "No passengers to show")) +
                "\n\t"
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }