var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "header card-header pb-0 mr-0 ml-0" },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("h5", { staticClass: "text-capitalize" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.rendTime(_vm.cardData.departureDate))),
              ]),
            ]),
          ]),
          !_vm.isProduction
            ? _c(
                "b-col",
                {
                  staticClass: "text-right",
                  staticStyle: { "padding-right": "0px !important" },
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "custom-dropdown",
                      staticStyle: {
                        "border-right": "0px !important",
                        "padding-top": "0px !important",
                        position: "relative",
                      },
                      attrs: {
                        "no-caret": "",
                        dropleft: "",
                        "no-flip": "",
                        offset: "25",
                        variant: "none",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [_c("more-vertical")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        664767440
                      ),
                    },
                    [
                      _vm.isArrived(_vm.cardData) === false
                        ? _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleStartStop(_vm.cardData)
                                },
                              },
                            },
                            [
                              _c("Car"),
                              _vm._v("  \n\t\t\t\t\t"),
                              _vm.isStarted(_vm.cardData) === false
                                ? _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(60, "Start transport"))
                                    ),
                                  ])
                                : _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.FormMSG(70, "Stop transport")
                                        )
                                    ),
                                  ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item-button",
                        {
                          attrs: {
                            disabled:
                              _vm.cardData.fromAddressId === 0 ||
                              _vm.cardData.toAddressId === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewInMap(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c("MapPin"),
                          _vm._v("   "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.FormMSG(61, "View in map"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-item-button",
                        {
                          attrs: {
                            disabled: _vm.cardData.passenger.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickViewPassengers(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c("Users"),
                          _vm._v("   "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.FormMSG(62, "View passengers"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleEditTransport(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c("Edit"),
                          _vm._v("   "),
                          _c("b", [_vm._v(_vm._s(_vm.FormMSG(65, "Edit")))]),
                        ],
                        1
                      ),
                      _vm.canRemoveTransport
                        ? _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(_vm.cardData.id)
                                },
                              },
                            },
                            [
                              _c("Trash2", { attrs: { color: "tomato" } }),
                              _vm._v("   "),
                              _c("b", { staticStyle: { color: "tomato" } }, [
                                _vm._v(_vm._s(_vm.FormMSG(63, "Remove"))),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isProduction
            ? _c(
                "b-col",
                {
                  staticClass: "text-right",
                  staticStyle: { "padding-right": "0px !important" },
                  attrs: { cols: "3" },
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "custom-dropdown",
                      staticStyle: {
                        "border-right": "0px !important",
                        "padding-top": "0px !important",
                        position: "relative",
                      },
                      attrs: {
                        "no-caret": "",
                        dropleft: "",
                        "no-flip": "",
                        offset: "25",
                        variant: "none",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [_c("more-vertical")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        664767440
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleCopyTransport(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("Copy"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _vm._v("   "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.FormMSG(867786, "Copy"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-item-button",
                        {
                          attrs: {
                            disabled:
                              _vm.cardData.fromAddressId === 0 ||
                              _vm.cardData.toAddressId === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenNewWindow(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("MapPin"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _vm._v("   "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.FormMSG(61, "View in map"))),
                          ]),
                        ],
                        1
                      ),
                      _vm.cardData.arrivalTime !== "0001-01-01T00:00:00Z"
                        ? _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleFromThereToTransport(
                                    _vm.cardData.id
                                  )
                                },
                              },
                            },
                            [
                              _c(_vm.getLucideIcon("Car"), {
                                tag: "component",
                                attrs: { size: 16 },
                              }),
                              _vm._v("   "),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(689689, "From here go to"))
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleEditTransport(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c("Edit", { attrs: { size: 16 } }),
                          _vm._v("   "),
                          _c("b", [_vm._v(_vm._s(_vm.FormMSG(65, "Edit")))]),
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleDeleteTransport(_vm.cardData)
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("Trash"), {
                            tag: "component",
                            attrs: { size: 16, color: "tomato" },
                          }),
                          _vm._v("  \n\t\t\t\t\t"),
                          _c("b", { staticStyle: { color: "tomato" } }, [
                            _vm._v(_vm._s(_vm.FormMSG(63, "Remove"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card card-collapse",
          class: { "is-openned": _vm.isOpen },
        },
        [
          _c(
            "div",
            {
              staticClass: "card-header pt-0",
              on: {
                click: function ($event) {
                  return _vm.handleOpenCollapse()
                },
              },
            },
            [
              _c("div", [
                _vm.cardData.extraInfo != ""
                  ? _c("h5", [_vm._v(_vm._s(_vm.cardData.extraInfo))])
                  : _vm._e(),
                _vm.rendHours(_vm.cardData.departureTime)
                  ? _c(
                      "div",
                      { staticClass: "row pl-3" },
                      [
                        _c("Clock4", {
                          staticClass: "mr-2",
                          attrs: { size: 15, color: "#47C7BF" },
                        }),
                        _vm._v("  \n\t\t\t\t\t"),
                        _c("h6", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(2, "Departure") +
                                  ": " +
                                  _vm.rendHours(_vm.cardData.departureTime)
                              ) +
                              "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.rendHours(_vm.cardData.arrivalTime)
                                  ? " - " +
                                      _vm.FormMSG(3, "Arrival") +
                                      ": " +
                                      _vm.rendHours(_vm.cardData.arrivalTime)
                                  : null
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("i", { staticClass: "icon-arrow-down" }),
            ]
          ),
          _c(
            "b-card-body",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              staticClass: "collapse-card-body bg-color-chefs-hat",
              class: { "is-openned": _vm.isOpen },
              staticStyle: { "margin-top": "-12px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "row pl-3 mb-2",
                  staticStyle: {
                    "flex-direction": "row",
                    "align-items": "center",
                  },
                },
                [
                  _c("ArrowLeftRight", {
                    staticClass: "mr-2",
                    staticStyle: { transform: "rotate(90deg)" },
                    attrs: { color: "#47C7BF", size: 15 },
                  }),
                  _c("div", { staticClass: "fs-13" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.FormMSG(10, "From") + ": ")
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.rendFormattedAddress(_vm.cardData.fromAddress)
                        )
                      ),
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.FormMSG(11, "To") + ": ")
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.rendFormattedAddress(_vm.cardData.toAddress))
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row pl-3 mb-2" },
                [
                  _c("Sprout", {
                    staticClass: "mr-2",
                    attrs: { color: "#47C7BF", size: 15 },
                  }),
                  _c("div", { staticClass: "fs-13" }, [
                    _vm._v(
                      _vm._s(
                        _vm.rendCo2(_vm.cardData.kgCoTwo) +
                          " - " +
                          _vm.cardData.km +
                          _vm.distanceUnit
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row pl-3 mb-2",
                  staticStyle: {
                    "flex-direction": "row",
                    "align-items": "center",
                  },
                },
                [
                  _c("Car", {
                    staticClass: "mr-2",
                    attrs: { color: "#47C7BF", size: 15 },
                  }),
                  _c("div", { staticClass: "fs-13" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.cardData.vehicule.name
                            ? ""
                            : _vm.rendTransportationMean(_vm.cardData.type)
                        ) +
                        "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.cardData.vehicule.name &&
                            _vm.cardData.vehicule.name +
                              " (" +
                              _vm.cardData.vehicule.numberPlate +
                              ")"
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ],
                1
              ),
              _vm.rendDifference(_vm.cardData)
                ? _c(
                    "div",
                    { staticClass: "row pl-3" },
                    [
                      _c("Clock4", {
                        staticClass: "mr-2",
                        attrs: { color: "#47C7BF", size: 15 },
                      }),
                      _c("div", { staticClass: "fs-13" }, [
                        _vm._v(
                          _vm._s(_vm.FormMSG(8, "Duration")) +
                            ": " +
                            _vm._s(_vm.rendDifference(_vm.cardData))
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }